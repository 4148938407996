import { reactive, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getUUID } from '@/common/js/tools';
import multiavatar from '@multiavatar/multiavatar';
import { globalDialog } from '@/common/js/tools';
import icons from '@/components/common/xicons/index.vue';
import Page from '@/common/js/page';
import { copyElementText } from '@/common/js/tools';
export default class Index extends Page {
    private user: any = reactive({
        money: 0,
        avatar: multiavatar('1234'),
        account: '',
    });
    private search: any = reactive({
        firstname: '',
        lastname: '',
        state: 'AK',
        birthyear: '',
    });
    private columns = [
        'firstname',
        'lastname',
        'city',
        'address',
        'StartDat',
        'aka3fullname',
        'middlename',
        'county_name',
        'aka1fullname',
        'aka2fullname',
        'alt2DOB',
        'alt1DOB',
        'phone1',
        'alt3DOB',
        'ssn',
        'dob',
        'ID',
        'zip',
        'state',
        'name_suff',
    ];
    private isInner = ref(false);
    private isNoData = ref(false);
    private openTitle = ref('Sign in');
    private dynamic = ref(null);
    private reminder = ref('');
    private isAside = ref(true);
    private isLogin = ref(false);
    private isRecharge = ref(false);
    private isLoginLoding = ref(false);
    private option = ref('Buy ssndob');
    private states: any = reactive([]);
    private isOpenQA = ref(false);
    private isLoadsh = ref(false);
    private padding = ref('20px 60px 0px 60px');
    public computeAvatar = computed(() => {
        return multiavatar(`${new Date().getTime()}`);
    });
    private menus = reactive([
        { key: 'ssndob', text: 'Buy ssndob', icon: 'ShoppingCart' },
        { key: 'possport', text: 'Possport photo', icon: 'ShoppingCartFull' },
        // { key: 'history', text: 'Order History', icon: 'Tickets' },
        { key: 'payable', text: 'Bitcoin', icon: 'Promotion' },
        { key: 'qa', text: 'Q&A', icon: 'Service' },
        { key: 'exit', text: 'Exit', icon: 'SwitchButton' },
    ]);
    private form: any = reactive({
        account: '',
        password: '',
        surePassword: '',
        code: '',
        invite: '',
        message: '',
    });
    constructor() {
        super();
        this.install();
    }
    public onChangeCode = () => {
        this.vercode.value = `/ssn/verification?t=${getUUID()}`;
        this.post('/ssn/getUserInfo', { data: { query: '1' } })
            .then((res: any) => {
                this.onUserInfo(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    private onInner() {
        this.isInner.value = document.documentElement.clientWidth <= 1000;
        this.padding.value = this.isInner.value ? '20px 2px 0px 2px' : '20px 60px 0px 60px';
        // console.log('padding = ', this.padding.value, ' this.isInner = ', this.isInner.value);
    }
    public install() {
        this.onInner();
        this.onChangeCode();
        this.mitter.on('listeneruser', (data: any) => {
            this.onUserInfo(data);
        });
        window.addEventListener('resize', (event) => {
            this.onInner();
        });
        this.onSearch();
        this.querystate();
    }
    private querystate() {
        this.get('/ssn/perstate', {})
            .then(({ data }: any) => {
                this.states.push(...data);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    private onUserInfo(data: any) {
        this.isLogin.value = data ? false : true;
        this.user.money = data ? data.user_money : 0;
        this.user.account = data ? data.user_account : '';
        this.user.avatar = multiavatar(data ? data.user_invite : getUUID());
    }
    public onMove = (e: object) => {
        console.log(e);
    };
    public onOpen = () => {
        globalDialog(icons, { message: '提示信息' });
    };
    public onSkipped = (item: any) => {
        this.isAside.value = true;
        if (item.key === 'qa') {
            this.isOpenQA.value = true;
        } else if (item.key === 'exit') {
            this.onExit();
        } else {
            this.option.value = item.text;
            this.dynamic.value = require(`../${item.key}`);
        }
    };
    public onConfirmqa = () => {
        if (!this.form.code) {
            ElMessage({ message: 'code is not null', type: 'warning' });
            return;
        }
        if (!this.form.message) {
            ElMessage({ message: 'message is not null', type: 'warning' });
            return;
        }
        const option = {
            code: this.form.code,
            message: this.form.message,
        };
        this.post('/ssn/question', { data: option })
            .then((res: any) => {
                this.isOpenQA.value = false;
                ElMessage({ message: 'Success', type: 'success' });
            })
            .catch((err: any) => {
                ElMessage({ message: err.message, type: 'error' });
            })
            .finally(() => {
                this.onChangeCode();
            });
    };
    public onSignup = () => {
        if (this.form.surePassword !== this.form.password) {
            this.reminder.value = 'Inconsistent password.';
            return;
        }
        this.isLoginLoding.value = true;
        const option = {
            user_account: this.form.account,
            user_password: this.form.password,
            sure_password: this.form.surePassword,
            user_invite: this.form.invite,
            check_code: this.form.code,
        };
        this.post('/ssn/register', { data: option })
            .then((res: any) => {
                if (res.code === '-1') {
                    this.reminder.value = res.message;
                } else {
                    this.isLogin.value = false;
                    ElMessage({ message: res.message, type: 'success' });
                }
            })
            .catch((err: any) => {
                this.reminder.value = err.message;
            })
            .finally(() => {
                this.isLoginLoding.value = false;
                this.onChangeCode();
            });
    };
    public onSignin = () => {
        let option = {
            user_account: this.form.account,
            user_password: this.form.password,
            vcode: this.form.code,
        };
        this.isLoginLoding.value = true;
        this.post('/ssn/login', { data: option })
            .then((res: any) => {
                if (res.code === '-1') {
                    this.reminder.value = res.message;
                } else {
                    this.isLogin.value = false;
                    ElMessage({ message: res.message, type: 'success' });
                }
            })
            .catch((err: any) => {
                this.reminder.value = err.message;
            })
            .finally(() => {
                this.isLoginLoding.value = false;
                this.onChangeCode();
            });
    };
    public resetReminder = (index: number) => {
        this.form.code = '';
        this.reminder.value = '';
        this.form.account = '';
        this.form.password = '';
        this.form.surePassword = '';
        this.form.invite = '';
        this.isLoginLoding.value = false;
        this.openTitle.value = index ? 'Sign up' : 'Sign in';
    };
    public onExit = () => {
        ElMessageBox.confirm('Are you sure to log out ?', 'usassn.shop', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
        })
            .then(() => {
                this.post('/ssn/logout')
                    .then((res: any) => {
                        this.user.account = '';
                        ElMessage({ type: 'success', message: res.message });
                    })
                    .catch((err) => {
                        ElMessage({ type: 'error', message: err.message });
                    });
            })
            .catch(() => {});
    };
    private isView = ref(true);
    private cases: any = reactive([]);
    public ignore = reactive(['ID', 'create_date', 'create_by', 'isSee']);
    public onSearch = () => {
        this.isView.value = false;
        this.isLoadsh.value = true;
        this.isNoData.value = false;
        this.cases.splice(0, this.cases.length);
        this.post('/ssn/pertesterSearch', { data: this.search })
            .then(({ data }: any) => {
                this.cases.push(...data);
            })
            .catch((err: any) => {
                console.log(err);
                ElMessage({ message: 'Query Error', type: 'error' });
            })
            .finally(() => {
                this.isLoadsh.value = false;
                this.isNoData.value = !this.cases.length;
            })
            .finally(() => {
                this.onChangeCode();
            });
    };
    public onSee = (item: any) => {
        ElMessageBox.confirm('Would you pay $3 to see that information ?', 'usassn.shop', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
        })
            .then(() => {
                item.isSee = true;
                this.post('/ssn/onSee', { data: { ID: item.ID } })
                    .then((res: any) => {
                        if (res.code === '1000') {
                            item.isShow = true;
                            item.ssn = res.data.ssn;
                        } else {
                            ElMessage({ message: res.message, type: 'error' });
                        }
                    })
                    .catch((err: any) => {
                        console.log(err);
                        ElMessage({ message: err.message, type: 'error' });
                    })
                    .finally(() => {
                        item.isSee = false;
                        this.onChangeCode();
                    });
            })
            .catch(() => {});
    };
    public onCopy = (item: any) => {
        copyElementText(`${JSON.stringify(item)}`);
    };
    public onRouter = (url: string) => {
        this.router.push({ name: url });
    };
}
